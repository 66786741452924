import * as yup from 'yup';
import { massiveDistributionMethods } from './MarketingMaterialFormConstants';
import validateFileExtension, { validateFileName } from '../../../../utils/helpers/formValidators';

const REQUIRED_MESSAGE = 'This field is required';

const hasMassiveDistributionMethods = (distributionMethod) => {
  if (distributionMethod !== null) {
    return distributionMethod.some((method) => massiveDistributionMethods.includes(method.value));
  }
  return false;
};

export const validationSchema = yup.object().shape({
  files: yup
    .array()
    .required(REQUIRED_MESSAGE)
    .test('', 'Some file has an invalid extension', validateFileExtension)
    .test('', 'Some file has an invalid name', validateFileName),
  // Question 1
  isRelatedToAnyFirmDeal: yup.boolean().required(REQUIRED_MESSAGE),

  // Question 2
  specificOrMultipleDeal: yup
    .object().nullable()
    .when('isRelatedToAnyFirmDeal', {
      is: true,
      then: yup.object().required(REQUIRED_MESSAGE)
        .test(
          'specificOrMultipleDeal',
          '',
          function (e) {
            if (this.parent.isRelatedToAnyFirmDeal === true) {
              return e?.value !== 'ONE_DEAL';
            }
            return true;
          }
        ),
      otherwise: yup.object(),
    }),

  // Question 3
  documentType: yup
    .object()
    .shape({ label: yup.string(), value: yup.string() })
    .required(REQUIRED_MESSAGE),

  // Question 3 -> Other Document
  otherDocument: yup
    .string()
    .when('documentType.value', {
      is: (documentTypeValue) => documentTypeValue === 'OTHER',
      then: yup.string().required(REQUIRED_MESSAGE),
      otherwise: yup.string().nullable(),
    }),

  //Question 4
  distributionMethod: yup
    .array()
    .of(yup.object().shape({ label: yup.string(), value: yup.string() }))
    .required(REQUIRED_MESSAGE),

  // Question 4 -> Other Method
  otherMethod: yup
    .string()
    .when('distributionMethod', {
      is: methods => methods && methods.some(method => method.value === 'OTHER'),
      then: yup.string().required(REQUIRED_MESSAGE),
      otherwise: yup.string().nullable()
    }),

  // Question 5
  exclusiveInvestors: yup
    .boolean()
    .nullable()
    .when('distributionMethod', {
      is: methods =>
        !methods?.some(method => 
          ['OPEN_WEBINAR', 'SOCIAL_MEDIA', 'WEBSITE', 'TRADITIONAL_MEDIA'].includes(method.value)
        ),
      then: yup.boolean().required(REQUIRED_MESSAGE),
      otherwise: yup.boolean().notRequired(),
    }),

  // Question 6
  massDistribution: yup
    .boolean()
    .nullable()
    .when('exclusiveInvestors', {
      is: false,
      then: yup.boolean().required(REQUIRED_MESSAGE),
      otherwise: yup.boolean().notRequired(),
    }),

  // Question 7
  financialRecommendation: yup
  .boolean()
  .nullable()
  .when(['massDistribution', 'distributionMethod'], {
    is: (massDistribution, distributionMethod) => 
      massDistribution === true || hasMassiveDistributionMethods(distributionMethod),
     then: yup.boolean().required(REQUIRED_MESSAGE),
     otherwise: yup.boolean().notRequired()
    }),

  // Question 8
  naturalPersonsAudience: yup
    .boolean()
    .when('exclusiveInvestors', {
      is: false,
      then: yup.boolean().required(REQUIRED_MESSAGE),
      otherwise: yup.boolean().nullable(),
    }),
});