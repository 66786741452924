import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import useStateModal from 'app/utils/hooks/useStateModal';

// mutations
import { CREATE_FORM } from 'api/graphql/compliance/mutations';

// context
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { DealsContext } from 'app/compliance/context/DealsContext';
import { FormsContext } from 'app/compliance/context/FormsContext';

import { FormType } from '../../../../../../types/graphql-types';

// hooks
import { FormikStub, useForm } from 'app/utils/hooks/useForm';

// validation schema
import { validationSchema } from './MarketingMaterialsFormValidation';
import { massiveDistributionMethods, MMAT_FORM_LABELS } from './MarketingMaterialsFormConstants';

export type SelectedValue = {
  value: string;
  label: string;
};

export type MarketingMaterialForm = {
  documentType: SelectedValue | null;
  distributionMethod: SelectedValue[] | null;
  whoWasInvolved: SelectedValue[] | null;
  isExclusivelyInvestors: boolean | null;
  masiveDistribution: boolean | null;
  naturalPersons: boolean | null;
  otherDocument?: string;
  otherMethod?: string;
  otherInvolved?: string;
  additionalNotes: string;
  files: File[];
};

const initialValues = {
  documentType: null,
  distributionMethod: null,
  masiveDistribution: null,
  whoWasInvolved: null,
  naturalPersons: null,
  isExclusivelyInvestors: null,
  additionalNotes: '',
  otherDocument: '',
  otherMethod: '',
  otherInvolved: '',
  files: [],
};

export interface UseMarketingMaterialFormHandler {
  form: FormikStub<MarketingMaterialForm>;
  onSubmitMarketingMaterialForm: () => Promise<void>;
  submitting: boolean;
  findOptionInList: (
    listType: { label: string; value: string }[] | null,
    searchValue: string | string[]
  ) => boolean;
  hasMassiveDistributionMethods: (
    distributionMethod: { label: string; value: string }[] | null
  ) => boolean;
}

export const MarketingMaterialHandlerContext = React.createContext<UseMarketingMaterialFormHandler>(
  {} as UseMarketingMaterialFormHandler
);
export const MarketingMaterialHandlerProvider = MarketingMaterialHandlerContext.Provider;

export const useMarketingMaterialFormHandler = (): UseMarketingMaterialFormHandler => {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const { refetchAll } = useContext(FormsContext);
  const { selectedDeal: dealId } = useContext<any>(DealsContext);
  const { hide, modalState } = useStateModal('MarketingMaterialsFormDrawer');

  const form = useForm<MarketingMaterialForm>({
    initialValues,
    validationSchema,
  });

  const regulatory = get(modalState, 'props.isRegulatory', false);

  const { resetForm } = form;

  const onSuccess = () => {
    hide();
    resetForm();
    if (regulatory) {
      refetchAll(['regulatoryProfile', 'MMRCount']);
    } else {
      refetchAll(['complianceTracker', 'dealRegulation']);
    }
  };

  const [createFormMutation, { loading: loadingForm }] = useMutation(CREATE_FORM, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      showSuccessToast({ title: 'Success!', description: 'Form was created successfully' });
      onSuccess();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const submitting = loadingForm;

  const buildMultOptionsTypeList = (
    listType: { label: string; value: string }[] | null
  ): string => {
    if (listType) {
      const list = listType.map((option) => option.label).join(', ');
      return list;
    }
    return '';
  };

  const findOptionInList = (
    listType: { label: string; value: string }[] | null,
    searchValue: string | string[]
  ): boolean => {
    if (listType) {
      if (Array.isArray(searchValue)) {
        return searchValue.some((value) => listType.some((item) => item.value === value));
      } else {
        return listType.some((item) => item.value === searchValue);
      }
    }
    return false;
  };

  const hasMassiveDistributionMethods = (distributionMethod) => {
    if (distributionMethod !== null) {
      return findOptionInList(distributionMethod, massiveDistributionMethods);
    }
    return false;
  };

  const buildMMForm = (props: MarketingMaterialForm, dealId: string) => {
    const mapYesNoToString = (value: boolean | null): string => (value ? 'Yes' : 'No');
    const {
      files,
      additionalNotes,
      documentType,
      isExclusivelyInvestors,
      masiveDistribution,
      naturalPersons,
      whoWasInvolved,
      otherDocument,
      otherInvolved,
      distributionMethod,
      otherMethod,
    } = props;

    const checkConditionalFields = (fieldName) => {
      switch (fieldName) {
        case 'otherDocument':
          return get(documentType, 'value') === 'OTHER' ? JSON.stringify(otherDocument) : null;

        case 'otherMethod':
          return findOptionInList(distributionMethod, 'OTHER') ? JSON.stringify(otherMethod) : null;

        case 'isExclusivelyInvestors':
          return distributionMethod !== null && !hasMassiveDistributionMethods(distributionMethod)
            ? JSON.stringify(isExclusivelyInvestors)
            : null;

        case 'masiveDistribution':
          return distributionMethod !== null &&
            !hasMassiveDistributionMethods(distributionMethod) &&
            isExclusivelyInvestors === false
            ? JSON.stringify(masiveDistribution)
            : null;

        case 'naturalPersons':
          return distributionMethod !== null &&
            !hasMassiveDistributionMethods(distributionMethod) &&
            isExclusivelyInvestors === false
            ? JSON.stringify(naturalPersons)
            : null;
        case 'otherInvolved':
          return findOptionInList(whoWasInvolved, 'OTHER') ? JSON.stringify(otherInvolved) : null;

        default:
          return '';
      }
    };

    const rawContent = [
      {
        question: MMAT_FORM_LABELS.DOCUMENT_TYPE,
        input: get(documentType, 'label'),
        key: 'documentType',
        value: JSON.stringify(documentType),
      },
      {
        question: MMAT_FORM_LABELS.OTHER_DOCUMENT,
        input: otherDocument,
        key: 'otherDocument',
        value: checkConditionalFields('otherDocument'),
      },
      {
        question: MMAT_FORM_LABELS.DISTRIBUTION_METHOD,
        input: buildMultOptionsTypeList(distributionMethod),
        key: 'distributionMethod',
        value: JSON.stringify('distributionMethod'),
      },
      {
        question: MMAT_FORM_LABELS.OTHER_METHOD,
        input: otherMethod,
        key: 'otherMethod',
        value: checkConditionalFields('otherMethod'),
      },
      {
        question: MMAT_FORM_LABELS.EXCLUSIVELY_INVESTORS,
        input: mapYesNoToString(isExclusivelyInvestors),
        key: 'isExclusivelyInvestors',
        value: checkConditionalFields('isExclusivelyInvestors'),
      },
      {
        question: MMAT_FORM_LABELS.MMAT_DISTRIBUTION,
        input: mapYesNoToString(masiveDistribution),
        key: 'masiveDistribution',
        value: checkConditionalFields('masiveDistribution'),
      },
      {
        question: MMAT_FORM_LABELS.NATURAL_PERSONS,
        input: mapYesNoToString(naturalPersons),
        key: 'naturalPersons',
        value: checkConditionalFields('naturalPersons'),
      },
      {
        question: MMAT_FORM_LABELS.WHO_WAS_INVOLVED,
        input: buildMultOptionsTypeList(whoWasInvolved),
        key: 'whoWasInvolved',
        value: JSON.stringify(whoWasInvolved),
      },
      {
        question: MMAT_FORM_LABELS.OTHER_INVOLVED,
        input: otherInvolved,
        key: 'otherInvolved',
        value: checkConditionalFields('otherInvolved'),
      },
    ];

    const content = rawContent.filter((item) => item.value !== null);
    const variables = {
      type: regulatory ? FormType.MMR : FormType.MMAT,
      content: content,
      dealId: !regulatory ? dealId : null,
      note: additionalNotes,
      files: files,
    };

    return variables;
  };

  const onSubmitMarketingMaterialForm = async (): Promise<void> => {
    const { values } = form;
    await createFormMutation({
      variables: buildMMForm(values, dealId),
    });
  };

  return {
    form,
    onSubmitMarketingMaterialForm,
    submitting,
    findOptionInList,
    hasMassiveDistributionMethods,
  };
};
