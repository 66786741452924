import { useEffect, useRef, useState, useContext } from 'react';
import { useClient, SplitContext } from '@splitsoftware/splitio-react';

import { UserContext } from 'app/users/context/UserContext';
import { EFeatureFlag } from 'app/FeatureFlag';
import { PlatformRole } from '../../core-tools/due-diligence/types/types';

/**
 * Keeps track of the previous value of the provided object.
 * @param value Current object value.
 * @returns The previous value of the passed object.
 */
export function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

/**
 * Checks if an object value has changed with respect to the previous passed one.
 * @param value Current object value.
 * @returns A flag indicating if the current object value has changed with respect to the previous. True means that it has changed and false that it doesn't.
 */
export function useCompare(value: any) {
  const prevValue = usePrevious(value);

  return prevValue !== value;
}

/**
 * Check passwords strength following 3 criterias:
 *  (1) more than 8 chars,
 *  (2) at least one uppercase and one lowercase
 *  and (3) at least one special char ONLY from these: @ $ ! % * ? &
 * @param typedPassword Current password typed by the user.
 * @returns An object containing the current strength state of the typed password based on verification criterias.
 */
export function usePasswordStrength(typedPassword: string) {
  const [currentStrength, setCurrentStrength] = useState<{
    moreThan8chars?: boolean;
    atLeast1uppercase1lowercase?: boolean;
    atLeast1specialChar?: boolean;
  }>({
    moreThan8chars: undefined,
    atLeast1uppercase1lowercase: undefined,
    atLeast1specialChar: undefined,
  });

  useEffect(() => {
    const moreThan8chars = /.{8,}/.test(typedPassword);
    const atLeast1uppercase1lowercase = /^(?=.*[a-z])(?=.*[A-Z])/.test(typedPassword);
    const atLeast1specialChar = /(?=.*[@$!%*?&])/.test(typedPassword);

    return () => {
      setCurrentStrength({ moreThan8chars, atLeast1uppercase1lowercase, atLeast1specialChar });
    };
  }, [typedPassword]);

  return currentStrength;
}

/**
 * Finds the state (active or inactive) of a given array or single feature flag.
 * @param features The features to ask its activation state.
 * @returns True or false based on the activation state (active: true,
 * inactive: false) of the feature flag(s) provided.
 */
export function useFeatureFlags(
  features: EFeatureFlag | EFeatureFlag[],
  emailToUse?: string
): boolean | { [key: string]: boolean } {
  let { email } = useContext(UserContext);
  if (emailToUse) email = emailToUse;
  const client = useClient(email || undefined);
  const { isReady } = useContext(SplitContext);

  if (process.env.NODE_ENV === 'test' || process.env.CI === 'true') return true;
  if (!isReady) return false;

  const treatments = client ? client.getTreatmentsWithConfig(Object.keys(EFeatureFlag)) : {};

  if (Array.isArray(features)) {
    return features.reduce(
      (acc, feature) => ({ ...acc, [feature]: treatments[feature].treatment === 'on' }),
      {}
    );
  }

  return treatments[features] && treatments[features].treatment === 'on';
}

/**
 * Check if the user has the given role
 * @param role The platform's role to ask whether it is activated
 * @return True or false based on the platform role
 */
export function usePlatformRole(role: PlatformRole): boolean {
  const { platformRole } = useContext(UserContext);

  return platformRole === role;
}
