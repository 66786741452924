export const MMLabels = {
  FILE_UPLOAD:
    'Please upload any marketing materials not previously submitted to Compliance or send to compliance@finalis.com',
  IS_RELATED_ANY_FIRMS_DEALS: 'Is this document related to any of your firm’s active deals?',
  SPECIFIC_OR_MULTIPLE_DEAL:
    'Is this document making reference to one specific active deal or multiple deals?',
  DOCUMENT_TYPE: 'Document type',
  DISTRIBUTION_METHOD: 'Distribution method',
  OTHER_DOCUMENT: 'Please describe',
  OTHER_METHOD: 'Please describe',
  EXCLUSIVE_INVESTORS:
    'Will the marketing materials be distributed exclusively to institutional investors?',
  MASS_DISTRIBUTION:
    'Will you distribute the marketing materials to more than 25 entities or persons within any 30-day calendar period?',
  NATURAL_PERSONS_AUDIENCE: 'Does the targeted audience include natural persons?',
  FINANCIAL_RECOMMENDATION:
    'Does the attached document make any financial or investment recommendation (e.g., a reference to a securities offering, a securities transaction taking place, or the expected use of proceeds of an offering) or promote your company’s services?',
  CREATION_TEAM: 'Who was involved in the creation of this material?',
  OTHER_CREATION_TEAM: 'Other creation team',
  ADDITIONAL_NOTES: 'Additional notes',
  EXCLUSIVE_INVESTORS_INFO:
    'Provide a definition of institutional investors, e.g., "An organization typically represented by an investment or financial professional, such as banks, insurance companies, or any person or entity with total assets of at least $50 million.',
};

export const DocumentsTypesOptions = [
  {
    label: 'Marketing Deck',
    value: 'MARKETING_DECK',
    description: 'Presentation that includes Industry or Service Pitch Deck.',
  },
  {
    label: 'Press Release',
    value: 'PRESS_RELEASE',
    description: 'Press Release to traditional media that also includes podcast.',
  },
  {
    label: 'Newsletter and non-deal related email communication',
    value: 'NEWSLETTER_NON_DEAL_EMAIL',
    description: 'Newsletter or any public communication not related to a specific deal.',
  },
  {
    label: 'Webinars, Workshops, Interviews, Video Presentations',
    value: 'WEBINARS_WORKSHOPS_INTERVIEWS_VIDEOS',
    description: 'Potential Webinars, interviews, and video presentations shared with the public.',
  },
  {
    label: 'Website review',
    value: 'WEBSITE_SOCIAL_MEDIA_POSTS',
    description: 'Communication to be posted on the website.',
  },
  {
    label: 'Business Card',
    value: 'BUSINESS_CARD',
  },
  {
    label: 'Other',
    value: 'OTHER',
    description: 'Please describe.',
  },
];

export const ReferenceDealOptions = [
  {
    label: 'One deal',
    value: 'ONE_DEAL',
  },
  {
    label: 'Multiple deals',
    value: 'MULTIPLE_DEALS',
  },
];

export const DistributionMethodsOptions = [
  {
    label: 'Email or traditional mailing',
    value: 'EMAIL',
    description: 'Post office',
  },
  {
    label: 'Closed Webinar',
    value: 'CLOSED_WEBINAR',
    description: 'Webinar where the number and type of attendees are controlled',
  },
  {
    label: 'Open Webinar',
    value: 'OPEN_WEBINAR',
    description: 'Webinar where the number and type of attendees are not controlled',
  },
  {
    label: 'Website',
    value: 'WEBSITE',
  },
  {
    label: 'Traditional Media',
    value: 'TRADITIONAL_MEDIA',
    description: 'e.g. TV, newspaper, magazine, etc.',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

enum EnumDistributionMethods {
  EMAIL_OR_TRADITIONAL_MAILING = 'EMAIL_OR_TRADITIONAL_MAILING',
  CLOSED_WEBINAR = 'CLOSED_WEBINAR',
  OPEN_WEBINAR = 'OPEN_WEBINAR',
  SOCIAL_MEDIA_POST = 'SOCIAL_MEDIA_POST',
  WEBSITE = 'WEBSITE',
  TRADITIONAL_MEDIA = 'TRADITIONAL_MEDIA',
  OTHER = 'OTHER',
}

export const massiveDistributionMethods: string[] = [
  EnumDistributionMethods.OPEN_WEBINAR,
  EnumDistributionMethods.WEBSITE,
  EnumDistributionMethods.TRADITIONAL_MEDIA,
];

export const acceptedFiles =
  '.jpg, .jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .png, image/jpeg, image/png, text/plain, .doc, .docx, .xls, .xlsx, .ppt, .pptx, application/excel, application/vnd.ms-excel, application/x-excel, application/x-msexcel, application/doc, application/ms-doc, application/msword, application/pdf, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation';

export const OneDealInfo =
  "For marketing materials related to one specific deal, please submit this document through the 'Marketing Materials' module of such a deal.";
