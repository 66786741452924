import React, { useContext } from 'react';
import { useMutation } from '@apollo/client';
import get from 'lodash/get';
import useStateModal from 'app/utils/hooks/useStateModal';

// mutations
import { CREATE_FORM, CREATE_NOTE } from 'api/graphql/compliance/mutations';

// context
import { AlertContext } from 'app/notifications/context/Alert/Alert';
import { DealsContext } from 'app/compliance/context/DealsContext';
import { FormsContext } from 'app/compliance/context/FormsContext';

import { FormType } from '../../../../../types/graphql-types';

// hooks
import { FormikStub, useForm } from 'app/utils/hooks/useForm';

// validation schema
import { validationSchema } from './MarketingMaterialFormValidations';
import { massiveDistributionMethods, MMLabels } from './MarketingMaterialFormConstants';

const initialValues = {
  note: '',
  files: [],
  isRelatedToAnyFirmDeal: null,
  specificOrMultipleDeal: null,
  documentType: null,
  distributionMethod: [],
  otherDocument: '',
  otherMethod: '',
  exclusiveInvestors: null,
  massDistribution: null,
  naturalPersonsAudience: null,
  financialRecommendation: null,
};

export type SelectedValue = {
  value: string;
  label: string;
};

export type MarketingMaterialForm = {
  note?: string;
  files: File[];
  isRelatedToAnyFirmDeal: boolean | null;
  specificOrMultipleDeal: SelectedValue | null;
  documentType: SelectedValue | null;
  distributionMethod: SelectedValue[] | null;
  otherDocument?: string;
  otherMethod?: string;
  exclusiveInvestors: boolean | null;
  massDistribution: boolean | null;
  naturalPersonsAudience: boolean | null;
  financialRecommendation: boolean | null;
};

export interface UseMarketingMaterialFormHandler {
  form: FormikStub<MarketingMaterialForm>;
  onSubmitMarketingMaterialForm: () => Promise<void>;
  submitting: boolean;
  findOptionInList: (
    listType: { label: string; value: string }[] | null,
    searchValue: string | string[]
  ) => boolean;
  hasMassiveDistributionMethods: (
    distributionMethod: { label: string; value: string }[] | null
  ) => boolean;
}

export const MarketingMaterialHandlerContext = React.createContext<UseMarketingMaterialFormHandler>(
  {} as UseMarketingMaterialFormHandler
);
export const MarketingMaterialHandlerProvider = MarketingMaterialHandlerContext.Provider;

export const useMarketingMaterialFormHandler = (): UseMarketingMaterialFormHandler => {
  const { showErrorToast, showSuccessToast } = useContext(AlertContext);
  const { refetchAll } = useContext(FormsContext);
  const { selectedDeal: dealId } = useContext<any>(DealsContext);
  const { hide, modalState } = useStateModal('marketingMaterialForm');

  const isEdit = get<boolean>(modalState, 'props.isEdit', false);
  const formId = get<string>(modalState, 'props.formId', '');

  const form = useForm<MarketingMaterialForm>({
    initialValues,
    validationSchema,
  });

  const regulatory = get(modalState, 'props.isRegulatory', false);

  const { resetForm } = form;

  const [createNote, { loading: isNoteSubmitting }] = useMutation(CREATE_NOTE);

  const handleFormUpdate = ({ note, files }: { note: string; files: File[] }) => {
    return createNote({
      variables: {
        input: {
          type: 'NOTE',
          content: note,
          entity: 'FORM',
          entityId: formId,
          attachments: files,
          private: false,
        },
      },
    }) as Promise<unknown>;
  };

  const buildDistributionMethodList = (
    distributionMethod: { label: string; value: string }[] | null
  ): string => {
    if (distributionMethod) {
      const distributionList = distributionMethod.map(option => option.label).join(', ');
      return distributionList;
    }
    return '';
  };

  const onSuccess = () => {
    hide();
    resetForm();
    if (regulatory) {
      refetchAll(['regulatoryProfile', 'MMRCount']);
    } else {
      refetchAll(['complianceTracker', 'dealRegulation']);
    }
  };

  const [createFormMutation, { loading: loadingForm }] = useMutation(CREATE_FORM, {
    fetchPolicy: 'no-cache',
    onCompleted() {
      showSuccessToast({ title: 'Success!', description: 'Form was created successfully' });
      onSuccess();
    },
    onError() {
      showErrorToast({ title: 'Error', description: 'An error occurred while saving the form' });
    },
  });

  const submitting = loadingForm || isNoteSubmitting;

  const findOptionInList = (
    listType: { label: string; value: string }[] | null,
    searchValue: string | string[]
  ): boolean => {
    if (listType) {
      if (Array.isArray(searchValue)) {
        return searchValue.some(value => listType.some(item => item.value === value));
      } else {
        return listType.some(item => item.value === searchValue);
      }
    }
    return false;
  };

  const hasMassiveDistributionMethods = (distributionMethod) => {
    if (distributionMethod !== null) {
      return findOptionInList(distributionMethod, massiveDistributionMethods);
    }
    return false;
  };

  const buildMMForm = (props: MarketingMaterialForm, dealId: string) => {
    const mapYesNoToString = (value: boolean | null): string => (value ? 'Yes' : 'No');
    const {
      files,
      note,
      isRelatedToAnyFirmDeal,
      specificOrMultipleDeal,
      documentType,
      otherDocument,
      distributionMethod,
      otherMethod,
      exclusiveInvestors,
      massDistribution,
      naturalPersonsAudience,
      financialRecommendation,
    } = props;
    
    const checkConditionalFields = fieldName => {
      switch (fieldName) {
        case 'specificOrMultipleDeal':
          return isRelatedToAnyFirmDeal === true ? JSON.stringify(specificOrMultipleDeal) : null;

        case 'otherDocument':
          return get(documentType, 'label') === 'OTHER' ? JSON.stringify(otherDocument) : null;

        case 'otherMethod':
          return findOptionInList(distributionMethod, 'OTHER') ? JSON.stringify(otherMethod) : null;

        case 'exclusiveInvestors':
          return distributionMethod !== null && !hasMassiveDistributionMethods(distributionMethod)
          ? JSON.stringify(exclusiveInvestors)
          : null;

        case 'massDistribution':
          return distributionMethod !== null &&
          !hasMassiveDistributionMethods(distributionMethod) &&
          exclusiveInvestors === false
          ? JSON.stringify(massDistribution)
          : null;
          
          case 'financialRecommendation':
            return massDistribution === true ||
            (distributionMethod !== null && hasMassiveDistributionMethods(distributionMethod))
              ? JSON.stringify(financialRecommendation)
              : null;

        case 'naturalPersonsAudience':
          return distributionMethod !== null &&
          !hasMassiveDistributionMethods(distributionMethod) &&
          exclusiveInvestors === false
          ? JSON.stringify(naturalPersonsAudience)
          : null;

        default:
          return '';
      }
    };
    const rawContent = [
        {
          question: MMLabels.IS_RELATED_ANY_FIRMS_DEALS,
          input: mapYesNoToString(isRelatedToAnyFirmDeal),
          key: 'isRelatedToAnyFirmDeal',
          value: JSON.stringify(isRelatedToAnyFirmDeal),
        },
        {
          question: MMLabels.SPECIFIC_OR_MULTIPLE_DEAL,
          input: get(specificOrMultipleDeal, 'label'),
          key: 'specificOrMultipleDeal',
          value: checkConditionalFields('specificOrMultipleDeal'),
        },
        {
          question: MMLabels.DOCUMENT_TYPE,
          input: get(documentType, 'label'),
          key: 'documentType',
          value: JSON.stringify(documentType),
        },
        {
          question: MMLabels.OTHER_DOCUMENT,
          input: otherDocument,
          key: 'otherDocument',
          value: checkConditionalFields('otherDocument'),
        },
        {
          question: MMLabels.DISTRIBUTION_METHOD,
          input: buildDistributionMethodList(distributionMethod),
          key: 'distributionMethod',
          value: JSON.stringify(distributionMethod),
        },
        {
          question: MMLabels.OTHER_METHOD,
          input: otherMethod,
          key: 'otherMethod',
          value: checkConditionalFields('otherMethod'),
        },
        {
          question: MMLabels.EXCLUSIVE_INVESTORS,
          input: mapYesNoToString(exclusiveInvestors),
          key: 'exclusiveInvestors',
          value: checkConditionalFields('exclusiveInvestors'),
        },
        {
          question: MMLabels.MASS_DISTRIBUTION,
          input: mapYesNoToString(massDistribution),
          key: 'massDistribution',
          value: checkConditionalFields('massDistribution'),
        },
        {
          question: MMLabels.FINANCIAL_RECOMMENDATION,
          input: mapYesNoToString(financialRecommendation),
          key: 'financialRecommendation',
          value: checkConditionalFields('financialRecommendation'),
        },
        {
          question: MMLabels.NATURAL_PERSONS_AUDIENCE,
          input: mapYesNoToString(naturalPersonsAudience),
          key: 'naturalPersonsAudience',
          value: checkConditionalFields('naturalPersonsAudience'),
        }
      ];

    const content = rawContent.filter(item => item.value !== null);
    const variables = {
      type: regulatory ? FormType.MMR : FormType.MMAT,
      content: content,
      dealId: !regulatory ? dealId : null,
      note,
      files: files,
    };

    return variables;
  };

  const onSubmitMarketingMaterialForm = async (): Promise<void> => {
    const { values } = form;
    if (isEdit) {
      try {
        if (values.note || (values.files && values.files.length)) {
          await handleFormUpdate({ note: values.note ?? '', files: values?.files });
          showSuccessToast({ title: 'Success!', description: 'Form was updated successfully' });
        }
        onSuccess();
      } catch (e) {
        showErrorToast({
          title: 'Error',
          description: 'An error occurred while updating the form',
        });
      }
    } else {
      await createFormMutation({
        variables: buildMMForm(values, dealId),
      });
    }
  };

  return { form, onSubmitMarketingMaterialForm, submitting, findOptionInList, hasMassiveDistributionMethods };
};
