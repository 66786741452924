import React, { useEffect, useState, useMemo } from 'react';
import get from 'lodash/get';
import { Role, DealSide, PlatformRole } from 'app/core-tools/due-diligence/types/types';
import { IContext } from './TypedUserContext';
import jwtDecode from 'jwt-decode';
import { useQuery, useMutation } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';

import { GET_USER_PROFILE } from 'api/graphql/users/queries';
import { LOGOUT } from 'api/graphql/users/mutations';
import AppStorage, { TOKEN_KEY, EMAIL_KEY } from 'app/appStorage';
import getUserToken from 'app/utils/helpers/getUserToken';
import { JWT } from 'app/core-tools/due-diligence/vdr/utils/types';
import {
  GET_USER_PROFILE_QUERY,
  GET_USER_PROFILE_QUERY_profile,
} from 'api/graphql/users/types/GET_USER_PROFILE_QUERY';
import { LOGOUT_MUTATION } from 'api/graphql/users/types/LOGOUT_MUTATION';
import { GET_DEAL } from 'api/graphql/deals/queries';
import {
  GET_DEAL_QUERY,
  GET_DEAL_QUERYVariables,
  GET_DEAL_QUERY_deal,
} from 'api/graphql/deals/types/GET_DEAL_QUERY';
import { mapDealMetadataToObject } from 'app/utils/helpers/dealMetadataMappers';
import { IDeal } from 'app/utils/types/types';
import { GET_MARKETPLACE_USER } from 'api/graphql/marketplace/queries';
import { GET_LATEST_ATTESTATION } from 'api/graphql/compliance/queries';
import {
  getLastAttestation,
  getLastAttestationVariables,
} from 'api/graphql/compliance/types/getLastAttestation';
import { mapMetadata } from 'app/utils/helpers/metadataMapper';
import { ONBOARDING_DATE } from 'app/compliance/constants/constants';

const initialContextValues = {
  exp: 0,
  id: '',
  user: {},
  originRole: '',
  role: '' as Role,
  platformRole: '' as PlatformRole,
  side: '' as DealSide,
  crdNumber: '',
  permissions: {
    vdr: {},
  },
  displayName: 'Loading',
  organization: {
    id: '',
    name: '',
  },
  metadata: null,
  attestation: null,
  createdAt: '',
  bankInfo: {
    bankName: '',
    bankAccountName: '',
    bankAccountNumber: '',
    abaNumber: '',
    attention: '',
    swiftCode: '',
    furtherBankAccountName: '',
    furtherBankAccountNumber: '',
  },
  stateRegInfo: [] as string[],
  activeDeal: {},
  isNewUser: false,
};

export const UserContext = React.createContext<IContext>(initialContextValues as IContext);
export const UserContextProvider = UserContext.Provider;
export const UserContextConsumer = UserContext.Consumer;

export const useUserContextHandler = (): IContext => {
  const userToken = getUserToken();
  const decodedToken = userToken ? jwtDecode<JWT>(userToken) : ({} as JWT);
  const [currentFirm, setCurrentFirmState] = useState(null);
  const [loadingState, setLoadingState] = useState(false);
  const { logout: auth0Logout } = useAuth0();

  const {
    data: profileData,
    loading: loadingProfileData,
    error: errorOnProfileData,
    refetch: refetchProfile,
    client,
  } = useQuery<GET_USER_PROFILE_QUERY>(GET_USER_PROFILE, {
    skip: decodedToken && !decodedToken.id,
  });

  const {
    data: dealData,
    loading: loadingDealData,
    error: errorOnDeal,
    refetch: refetchActiveDeal,
  } = useQuery<GET_DEAL_QUERY, GET_DEAL_QUERYVariables>(GET_DEAL, {
    variables: { id: decodedToken.dealId },
    skip: !decodedToken.dealId,
    fetchPolicy: 'no-cache',
  });

  const {
    data: attestationData,
    loading: loadingAttestationData,
    refetch: refetchLatestAttestation,
  } = useQuery<getLastAttestation, getLastAttestationVariables>(GET_LATEST_ATTESTATION, {
    variables: { userId: decodedToken.id },
    skip: !decodedToken.id,
    fetchPolicy: 'no-cache',
  });

  const {
    data: marketplaceUserData,
    loading: loadingMarketplaceUserData,
    refetch: marketplaceUserRefetch,
  } = useQuery(GET_MARKETPLACE_USER, {
    variables: { userId: decodedToken.id },
    fetchPolicy: 'no-cache',
  });

  const clearSession = (): void => {
    datadogRum.stopSessionReplayRecording();
    AppStorage.removeKey(TOKEN_KEY);
    AppStorage.removeKey(EMAIL_KEY);
    AppStorage.removeKey('currentFirm');
    client.clearStore();
    if (process.env.REACT_APP_INTERCOM_APP_ID && window.Intercom) {
      window.Intercom('shutdown');
    }
    auth0Logout();
  };

  const [logout] = useMutation<LOGOUT_MUTATION>(LOGOUT, {
    onCompleted: clearSession,
    onError: clearSession,
  });

  const impersonatingUser = get(decodedToken, 'impersonatedBy', null);
  const isImpersonated = !!impersonatingUser;

  const MarketplaceProfile =
    !userToken || loadingMarketplaceUserData ? {} : get(marketplaceUserData, 'marketplaceUser', {});

  const profile = useMemo(
    () =>
      !userToken || loadingProfileData || errorOnProfileData
        ? ({} as GET_USER_PROFILE_QUERY_profile)
        : (profileData && profileData.profile) || ({} as GET_USER_PROFILE_QUERY_profile),
    [userToken, loadingProfileData, errorOnProfileData, profileData]
  );

  useEffect(() => {
    const defaultFirm = get(profile, 'firms[0]') || null;
    setCurrentFirmState(AppStorage.getItem('currentFirm') || defaultFirm);
  }, [profileData, profile]);

  const getCurrentFirm = () => {
    return currentFirm;
  };

  const setCurrentFirm = (firm: any): void => {
    setCurrentFirmState(firm);
    AppStorage.setItem('currentFirm', firm);
  };

  const activeDeal: any =
    !userToken || loadingDealData || errorOnDeal
      ? undefined
      : (dealData && dealData.deal) || undefined;

  const mapDealMetadata = (deal: GET_DEAL_QUERY_deal | undefined) => {
    if (deal && deal.metadata) {
      return {
        ...deal,
        metadata: mapDealMetadataToObject(deal.metadata),
      };
    }
    return deal;
  };

  const checkIfNewUser: boolean = useMemo(() => {
    if (!userToken || loadingProfileData || errorOnProfileData || !profile) {
      return false;
    }
    // We use ONBOARDING_DATE so that the users that are created after this date will have to do the onboarding process.
    const onboardingTime = new Date(ONBOARDING_DATE).getTime();
    const profileCreationTime = new Date(profile.createdAt).getTime();

    return profileCreationTime >= onboardingTime;
  }, [userToken, loadingProfileData, errorOnProfileData, profile]);

  if (profile) {
    datadogRum.addRumGlobalContext('user', {
      id: profile.id,
      email: profile.email,
      displayName: profile.displayName,
      role: profile.role,
      side: profile.side,
      organization: profile.organization && {
        id: profile.organization.id,
        name: profile.organization.name,
      },
    });
    if (
      process.env.REACT_APP_BASIC_URL &&
      process.env.REACT_APP_BASIC_URL.includes('my.finalis.com')
    ) {
      datadogRum.startSessionReplayRecording();
    }
  }
  return {
    exp: decodedToken.exp,
    id: decodedToken.id,
    crdNumber: profile.crdNumber || '',
    firstName: profile.firstName || 'Loading',
    lastName: profile.lastName || 'Loading',
    email: profile.email,
    role: decodedToken.role,
    platformRole: (decodedToken.platformRole as unknown) as PlatformRole,
    side: decodedToken.side,
    user: decodedToken,
    originRole: decodedToken.originRole,
    displayName: profile.displayName || 'Loading',
    mfaEnabled: profile.mfaEnabled,
    isCustomer: profile.isCustomer,
    isImpersonated,
    impersonatingUser,
    lastLoginInDealAt: profile.lastLoginInDealAt,
    hasPassword: profile.hasPassword || false,
    firms: profile.firms,
    metadata: mapMetadata(profile.metadata || []),
    refetchProfile,
    organization: (profile.organization as any) || {
      __typename: 'Organization',
      id: '',
      name: 'Loading',
    },
    attestation: get(attestationData, 'getLastAttestation', null),
    permissions: (profile.permissions as any) || {
      vdr: {} as any,
    },
    bankInfo: profile.bankInfo as any,
    stateRegInfo: profile.stateRegInfo as string[],
    createdAt: profile.createdAt,
    marketplaceAccessEnabled: MarketplaceProfile.marketplaceAccessEnabled,
    skipMarketplaceTour: !!MarketplaceProfile.skipMarketplaceTour,
    logout,
    getCurrentFirm,
    setCurrentFirm,
    currentFirm,
    activeDeal: mapDealMetadata(activeDeal) as IDeal,
    refetchActiveDeal,
    marketplaceUserRefetch,
    refetchLatestAttestation,
    loadingMarketplaceUserData,
    loadingDealData,
    loadingProfileData: loadingProfileData || loadingState,
    loadingState,
    setLoadingState,
    loadingAttestationData,
    isUserLoading: profile.displayName === 'Loading',
    lastLoginAt: profile.lastLoginAt,
    isNewUser: checkIfNewUser,
  };
};
