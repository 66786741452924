enum EnumDistributionMethods {
  EMAIL_OR_TRADITIONAL_MAILING = 'EMAIL_OR_TRADITIONAL_MAILING',
  CLOSED_WEBINAR = 'CLOSED_WEBINAR',
  OPEN_WEBINAR = 'OPEN_WEBINAR',
  SOCIAL_MEDIA_POST = 'SOCIAL_MEDIA_POST',
  WEBSITE = 'WEBSITE',
  TRADITIONAL_MEDIA = 'TRADITIONAL_MEDIA',
  OTHER = 'OTHER',
}

export const DocumentsTypesOptions = [
  {
    label: 'Marketing Deck and Investor communication',
    value: 'MARKETING_DECK_AND_INVESTOR_COMMUNICATION',
    description:
      'Any type of presentation related to a specific deal, such as decks, CIMs, teasers, executive summaries, or 1-pagers.',
  },
  {
    label: 'Press Release or Online Post',
    value: 'PRESS_RELEASE_OR_ONLINE_POST',
    description: 'Press releases to traditional media, podcasts, or posts on websites.',
  },
  {
    label: 'Financial Reports or Case Study',
    value: 'FINANCIAL_REPORTS_OR_CASE_STUDY',
    description: 'Case studies, due diligence reports, financial reports, proforma charts, etc.',
  },
  {
    label: 'Offering Documents',
    value: 'OFFERING_DOCUMENTS',
    description:
      'Operating Agreements, Private Placement Memoranda (PPM), Subscription Agreements.',
  },
  {
    label: 'Webinar, Interview and Video presentations',
    value: 'WEBINAR_INTERVIEW_AND_VIDEO_PRESENTATIONS',
    description: 'Webinars, interviews, or video presentations shared with the public.',
  },

  {
    label: 'Other',
    value: 'OTHER',
    description: 'Please describe.',
  },
];

export const DistributionMethods = [
  {
    label: 'Email or traditional mailing',
    value: EnumDistributionMethods.EMAIL_OR_TRADITIONAL_MAILING,
    description: 'Post office.',
  },
  {
    label: 'Closed Webinar',
    value: EnumDistributionMethods.CLOSED_WEBINAR,
    description: 'Webinar where the number and type of attendees are controlled.',
  },
  {
    label: 'Open Webinar',
    value: EnumDistributionMethods.OPEN_WEBINAR,
    description: 'Webinar where the number and type of attendees are not controlled.',
  },
  {
    label: 'Website',
    value: EnumDistributionMethods.WEBSITE,
  },
  {
    label: 'Traditional Media',
    value: EnumDistributionMethods.TRADITIONAL_MEDIA,
    description: 'e.g., TV, newspaper, magazine, etc.',
  },
  {
    label: 'Other',
    value: EnumDistributionMethods.OTHER,
  },
];

export const InvolvedOptions = [
  {
    label: 'Your Firm',
    value: 'YOUR_FIRM',
  },
  {
    label: 'Client',
    value: 'CLIENT',
  },
  {
    label: 'A service provider of the client',
    value: 'SERVICE_PROVIDER_CLIENT',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
];

export const MMAT_FORM_LABELS = {
  DOCUMENT_TYPE: 'Document type',
  DISTRIBUTION_METHOD: 'Distribution method',
  EXCLUSIVELY_INVESTORS:
    'Will the marketing materials be distributed exclusively to institutional investors?',
  MMAT_DISTRIBUTION:
    'Will you distribute the marketing materials to more than 25 entities or persons within any 30-day calendar period?',
  NATURAL_PERSONS: 'Does the targeted audience include natural persons?',
  WHO_WAS_INVOLVED: 'Who was involved in the creation of this material?',
  WHO_IS_THE_AUDIENCE: 'Who is the audience?',
  WAS_THE_CLIENT_INVOLVED: 'Was the client involved in creating this marketing material?',
  DID_YOU_CREATE_THIS_BY_YOURSELF: 'Did you create this marketing material yourself?',
  EXPLAIN_DOCUMENT: 'Explain how the document was created',
  OTHER_DOCUMENT: 'Please describe',
  OTHER_INVOLVED: 'Please describe',
  OTHER_METHOD: 'Please describe',
  EXCLUSIVELY_INVESTORS_INFO:
    'An organization typically represented by an investment or financial professional, such as banks, insurance companies, or any person or entity with total assets of at least $50 million.',
};

export const massiveDistributionMethods: string[] = [
  EnumDistributionMethods.OPEN_WEBINAR,
  EnumDistributionMethods.WEBSITE,
  EnumDistributionMethods.TRADITIONAL_MEDIA,
];
