import React, { useContext } from 'react';
import { Tooltip } from '@dealsyte/poki';

// Context
import { formControlChangeHandlers } from 'app/compliance/components/FormControlChangeHandlers/FormControlChangeHandlers';
import {
  MarketingMaterialHandlerContext,
  MarketingMaterialForm as MMATtype,
} from './MarketingMaterialsFormHandler';

// Components
import {
  FormFilePicker,
  FormGroup,
  Input,
  Select,
  SingleOptionRadioButtons,
  TextArea,
} from '../../../../../common/components';

// Icon
import { FilesIcon, RoundedInfoIcon } from '../../../../../common/icons';

// Constants
import {
  DocumentsTypesOptions,
  DistributionMethods,
  InvolvedOptions,
  MMAT_FORM_LABELS,
} from './MarketingMaterialsFormConstants';

// Style
import * as S from './MarketingMaterialsFormStyled';

const MarketingMaterialForm = () => {
  const { form, findOptionInList, hasMassiveDistributionMethods } = useContext(
    MarketingMaterialHandlerContext
  );
  const { values, setFieldValue, errors, touched } = form;

  const { handleFieldChange, handleSelectChange, handleYesNoChange, handleFilesChange } =
    formControlChangeHandlers<MMATtype>(setFieldValue);

  const acceptedFiles =
    '.doc, .docx, .xls, .xlsx, application/excel, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/x-excel, application/x-msexcel, application/doc, application/ms-doc, application/msword, application/pdf, .jpeg, .png, image/jpeg, image/png, image/x-png, application/vnd.openxmlformats-officedocument.wordprocessingml.document';

  return (
    <form>
      <S.Text>
        Upload and categorize marketing materials or public communications for compliance
        supervision.
      </S.Text>
      <S.DocumentInfoContainer>
        <div className="icon-container">
          <FilesIcon />
        </div>
        <S.Text>
          For multiple documents, fill out individual forms for each of them. [JPG, JPEG, PNG, DOC,
          DOCX, XLS, XLSX and PDF] are supported.
        </S.Text>
      </S.DocumentInfoContainer>
      <fieldset>
        <S.UploadButtonContainer>
          <FormFilePicker
            displayAs="button"
            files={values.files}
            error={touched.files && errors.files}
            setFiles={handleFilesChange('files')}
            acceptedFiles={acceptedFiles}
          />
        </S.UploadButtonContainer>
        <FormGroup label={MMAT_FORM_LABELS.DOCUMENT_TYPE} required>
          <Select
            inputId="documentType"
            name="documentType"
            maxMenuHeight={290}
            placeholder="Select type"
            isSearchable={false}
            options={DocumentsTypesOptions}
            value={values.documentType}
            onChange={handleSelectChange('documentType')}
          />
        </FormGroup>
        {values.documentType !== null && values.documentType.value === 'OTHER' && (
          <FormGroup
            id="otherDocument"
            showCharCount
            required
            label={MMAT_FORM_LABELS.OTHER_DOCUMENT}
            error={touched.otherDocument && errors.otherDocument}
          >
            {(ref) => (
              <Input
                ref={ref as any}
                id="otherDocument"
                maxLength={140}
                name="otherDocument"
                value={values.otherDocument}
                onChange={handleFieldChange('otherDocument')}
              />
            )}
          </FormGroup>
        )}
        <FormGroup
          label={MMAT_FORM_LABELS.DISTRIBUTION_METHOD}
          required
          error={touched.distributionMethod && errors.distributionMethod}
        >
          <Select
            inputId="distributionMethod"
            name="distributionMethod"
            maxMenuHeight={280}
            placeholder="Select distribution method(s)"
            isSearchable={false}
            options={DistributionMethods}
            isMulti
            value={values.distributionMethod}
            onChange={handleSelectChange('distributionMethod')}
          />
        </FormGroup>
        {values.distributionMethod !== null &&
          findOptionInList(values.distributionMethod, 'OTHER') && (
            <FormGroup
              id="otherMethod"
              showCharCount
              required
              label={MMAT_FORM_LABELS.OTHER_METHOD}
              error={touched.otherMethod && errors.otherMethod}
            >
              {(ref) => (
                <Input
                  ref={ref as any}
                  id="otherMethod"
                  maxLength={140}
                  name="otherMethod"
                  value={values.otherMethod}
                  onChange={handleFieldChange('otherMethod')}
                />
              )}
            </FormGroup>
          )}
        {values.distributionMethod !== null &&
          !hasMassiveDistributionMethods(values.distributionMethod) && (
            <FormGroup
              label={
                <span>
                  {MMAT_FORM_LABELS.EXCLUSIVELY_INVESTORS}
                  <Tooltip
                    message={MMAT_FORM_LABELS.EXCLUSIVELY_INVESTORS_INFO}
                    style={{ padding: 20, lineHeight: 1.5 }}
                  >
                    <RoundedInfoIcon style={{ marginLeft: 4, marginBottom: 3, color: '#6A678E' }} />
                  </Tooltip>
                </span>
              }
              required
            >
              <SingleOptionRadioButtons
                strictValueOnChange
                id="isExclusivelyInvestors"
                name="isExclusivelyInvestors"
                value={values.isExclusivelyInvestors}
                onChange={handleYesNoChange('isExclusivelyInvestors')}
              />
            </FormGroup>
          )}
        {values.distributionMethod !== null &&
          !hasMassiveDistributionMethods(values.distributionMethod) &&
          values.isExclusivelyInvestors === false && (
            <FormGroup label={MMAT_FORM_LABELS.MMAT_DISTRIBUTION} required>
              <SingleOptionRadioButtons
                strictValueOnChange
                id="masiveDistribution"
                name="masiveDistribution"
                value={values.masiveDistribution}
                onChange={handleYesNoChange('masiveDistribution')}
              />
            </FormGroup>
          )}
        {values.distributionMethod !== null &&
          !hasMassiveDistributionMethods(values.distributionMethod) &&
          values.isExclusivelyInvestors === false && (
            <FormGroup label={MMAT_FORM_LABELS.NATURAL_PERSONS} required>
              <SingleOptionRadioButtons
                strictValueOnChange
                id="naturalPersons"
                name="naturalPersons"
                value={values.naturalPersons}
                onChange={handleYesNoChange('naturalPersons')}
              />
            </FormGroup>
          )}
        <FormGroup label={MMAT_FORM_LABELS.WHO_WAS_INVOLVED} required>
          <Select
            inputId="whoWasInvolved"
            name="whoWasInvolved"
            maxMenuHeight={290}
            placeholder="Select type"
            isSearchable={false}
            options={InvolvedOptions}
            isMulti
            value={values.whoWasInvolved}
            onChange={handleSelectChange('whoWasInvolved')}
          />
        </FormGroup>
        {values.whoWasInvolved !== null && findOptionInList(values.whoWasInvolved, 'OTHER') && (
          <FormGroup
            id="otherInvolved"
            required
            showCharCount
            label={MMAT_FORM_LABELS.OTHER_INVOLVED}
            error={touched.otherInvolved && errors.otherInvolved}
          >
            {(ref) => (
              <Input
                ref={ref as any}
                id="otherInvolved"
                maxLength={140}
                name="otherInvolved"
                placeholder={MMAT_FORM_LABELS.OTHER_INVOLVED}
                value={values.otherInvolved}
                onChange={handleFieldChange('otherInvolved')}
              />
            )}
          </FormGroup>
        )}
        <FormGroup id="additionalNotes" optional showCharCount label="Additional notes">
          {(ref) => (
            <TextArea
              className="textArea"
              ref={ref as any}
              placeholder="Write your notes"
              id="additionalNotes"
              maxLength={400}
              name="additionalNotes"
              value={values.additionalNotes}
              onChange={handleFieldChange('additionalNotes')}
            />
          )}
        </FormGroup>
      </fieldset>
    </form>
  );
};

export default MarketingMaterialForm;
