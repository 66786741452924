// Vendors
import * as yup from 'yup';
import { massiveDistributionMethods } from './MarketingMaterialsFormConstants';
import { validateFileName } from 'app/utils/helpers/formValidators';

const REQUIRED_MESSAGE = 'This field is required';

const hasMassiveDistributionMethods = (distributionMethod) => {
  if (distributionMethod !== null) {
    return distributionMethod.some((method) => massiveDistributionMethods.includes(method.value));
  }
  return false;
};

function validateMmatFileExtension(files: File[]): boolean {
  const validation = files.every((file) => {
    const splitted = file.name.split('.');
    const extension = splitted[splitted.length - 1];
    const acceptedExtensions = [
      'docx',
      'DOCX',
      'doc',
      'DOC',
      'pdf',
      'PDF',
      'xls',
      'XLS',
      'xlsx',
      'XLSX',
      'PNG',
      'png',
      'JPG',
      'jpg',
      'JPEG',
      'jpeg',
    ];
    return acceptedExtensions.includes(extension);
  });
  return validation;
}

export const validationSchema = yup.object().shape({
  files: yup
    .array()
    .required(REQUIRED_MESSAGE)
    .test(
      '',
      'Some file has an invalid name',
      (array: File[]) => !!array && validateFileName(array)
    )
    .test(
      '',
      'The file has an invalid extension',
      (value) => value && value.length && validateMmatFileExtension(value)
    ),
  documentType: yup
    .object()
    .shape({ label: yup.string(), value: yup.string() })
    .required(REQUIRED_MESSAGE),
  otherDocument: yup
    .string()
    .nullable()
    .when('documentType', (documentType, schema) =>
      documentType !== null && documentType.value === 'OTHER'
        ? schema.required(REQUIRED_MESSAGE)
        : schema
    ),
  distributionMethod: yup
    .array()
    .nullable()
    .of(yup.object().shape({ label: yup.string(), value: yup.string() }))
    .required(REQUIRED_MESSAGE),
  otherMethod: yup
    .string()
    .nullable()
    .when('distributionMethod', (distributionMethod, schema) =>
      distributionMethod !== null && distributionMethod.some((item) => item.value === 'OTHER')
        ? schema.required(REQUIRED_MESSAGE)
        : schema
    ),
  isExclusivelyInvestors: yup
    .boolean()
    .nullable()
    .when('distributionMethod', (distributionMethod, schema) =>
      distributionMethod !== null && !hasMassiveDistributionMethods(distributionMethod)
        ? schema.required(REQUIRED_MESSAGE)
        : schema
    ),
  masiveDistribution: yup
    .boolean()
    .nullable()
    .when(['isExclusivelyInvestors', 'distributionMethod'], {
      is: (isExclusivelyInvestors, distributionMethod) => {
        return (
          isExclusivelyInvestors === false &&
          distributionMethod !== null &&
          !hasMassiveDistributionMethods(distributionMethod)
        );
      },
      then: (schema) => schema.required(REQUIRED_MESSAGE),
      otherwise: (schema) => schema,
    }),
  naturalPersons: yup
    .boolean()
    .nullable()
    .when(['isExclusivelyInvestors', 'distributionMethod'], {
      is: (isExclusivelyInvestors, distributionMethod) => {
        return (
          isExclusivelyInvestors === false &&
          distributionMethod !== null &&
          !hasMassiveDistributionMethods(distributionMethod)
        );
      },
      then: (schema) => schema.required(REQUIRED_MESSAGE),
      otherwise: (schema) => schema,
    }),
  whoWasInvolved: yup
    .array()
    .nullable()
    .of(yup.object().shape({ label: yup.string(), value: yup.string() }))
    .required(REQUIRED_MESSAGE),
  otherInvolved: yup
    .string()
    .nullable()
    .when('whoWasInvolved', (whoWasInvolved, schema) =>
      whoWasInvolved !== null && whoWasInvolved.some((item) => item.value === 'OTHER')
        ? schema.required(REQUIRED_MESSAGE)
        : schema
    ),
});
